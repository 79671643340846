.name {
    display: flex;
    grid-area: name;
    text-align: left;
    align-items: flex-end;
    color: var(--color-secondary);
}

.textName {
    transform: translateY(25%);
    margin-left: -2px;
}

.for_executive_h1 {
    grid-area: for_executive_h1;
}

.and_company_h1 {
    grid-area: and_company_h1;
}

.profile_img {
    display: flex;
    background-color: var(--color-primary);
    grid-area: profile_img;
    align-items: center;
    /*background-color: var(--color-primary);*/
    border: 0px;
}

.coaching_is_h1 {
    grid-area: coaching_is_h1;
}

.coaching_is_h3 {
    grid-area: coaching_is_h3;
}

.decisive_coaching_intro_h1_p1,
.decisive_coaching_intro_h1_p2 {
    display: flex;
    justify-content: flex-end;
    font-size: 2.22rem;
    align-items: flex-end;
    color: var(--color-secondary);
}

.for_executive_text,
.and_company_h1_text,
.decisive_coaching_intro_h1_p1_text,
.decisive_coaching_intro_h1_p2_text {
    transform: translateY(79%);
    margin-left: -4px;
}

.decisive_coaching_intro_h1_p1 {
    grid-area: decisive_coaching_intro_h1_p1;
    justify-content: flex-end;
}

.decisive_coaching_intro_h1_p2 {
    grid-area: decisive_coaching_intro_h1_p2;
    justify-content: flex-start;
}

@media (max-width: 1506px) {

    .decisive_coaching_intro_h1_p1,
    .decisive_coaching_intro_h1_p2 {
        font-size: 2rem;
    }

    .decisive_coaching_intro_h1_p1_text,
    .decisive_coaching_intro_h1_p2_text {
        transform: translateY(80%);
        margin-left: -4px;
    }
}

@media (max-width: 1148px) {

    .decisive_coaching_intro_h1_p1,
    .decisive_coaching_intro_h1_p2 {
        font-size: 1.5rem;
    }

    .decisive_coaching_intro_h1_p1_text,
    .decisive_coaching_intro_h1_p2_text {
        transform: translateY(80%);
        margin-left: -4px;
    }

    .for_executive_text,
    .and_company_h1_text {
        font-size: 1rem;
        transform: translateY(200%);
        margin-left: -4px;
    }
}

@media (max-width: 932px) {

    .decisive_coaching_intro_h1_p1,
    .decisive_coaching_intro_h1_p2 {
        font-size: 0.85rem;
    }

    .decisive_coaching_intro_h1_p1_text,
    .decisive_coaching_intro_h1_p2_text {
        transform: translateY(80%);
    }

    .decisive_coaching_intro_h1_p2_text {
        margin-left: 1px;
    }
}