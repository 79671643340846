@font-face {
  font-family: RidleyGrotesk;
  font-weight: lighter;
  src: url("../fonts/RidleyGroteskLight.otf") format("opentype");
}

@font-face {
  font-family: RidleyGrotesk;
  font-weight: semibold;
  src: url("../fonts/RidleyGroteskSemiBold.otf") format("opentype");
}

body {
  margin: 0;
  font-family: RidleyGrotesk, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin: 0;
}

@media (max-width: 768px) {
  h2 {
    font-size: 1rem;
  }
}

/* Old colors

:root {
  --color-primary: #1a1d3d;
  --color-secondary: #9ca5f4;
  --color-accent: #9ca5f484;
  --color-background: #F5F5F5;
  --color-text: #333333;
}

*/
:root {
  --color-primary: #151E3F;
  --color-secondary: #FE3F41;
  --color-accent: #fe3f4255;
  --color-background: #F5F5F5;
  --color-text: #333333;
}