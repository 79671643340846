.grid-background {
    display: grid;
    background-color: var(--color-background);
    /*height: 200vh;*/
    grid-template-columns: 1fr [margin_left] 10fr [quarter_right] 30fr [middle] 30fr [quarter_left] 10fr [margin_right] 1fr;
    /*grid-template-rows: 1fr 10fr 1.5fr 1.5fr 1.5fr 3fr 6fr 2.5fr 6fr 2fr 6fr 1fr;*/
    grid-template-rows: 0.33fr 3.33fr 0.5fr 0.5fr 0.5fr 1fr 3fr 0.5fr 0.83fr 2fr 0.5fr 0.67fr 2fr 0.33fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        ". name . profile_img profile_img profile_img"
        ". decisive_coaching_intro_h1_p1 decisive_coaching_intro_h1_p2 profile_img profile_img profile_img"
        ". . for_executive_h1 profile_img profile_img profile_img"
        ". . and_company_h1 profile_img profile_img profile_img"
        ". . . profile_img profile_img profile_img"
        ". advise_to_coaching_h3 about_h2 about_h2 . ."
        ". . about_h2 about_h2 . ."
        ". . . . . ."
        ". coaching_is_h1 coaching_is_h1 . . ."
        ". . coaching_is_h3 coaching_is_h3 . ."
        ". . . . . ."
        ". landmark_h2 chronology_h2 chronology_h2 . ."
        ". . chronology_h2 chronology_h2 . ."
        ". . . . . ."
        ". you_are . . . ."
        ". decisive_coaching_h3 . . . ."
        ". . . . . ."
        ". . . . . .";
}

.grid-background>* {
    border: 0.95px solid var(--color-accent);
    /* Applique une bordure à tous les enfants directement */
}

.advise_to_coaching_h3 {
    grid-area: advise_to_coaching_h3;
    color: white;
    background-color: var(--color-secondary);
    font-size: 1.2rem;
}

.you_are {
    grid-area: you_are;
    background-color: var(--color-secondary);
    color: white;
    font-size: 1.2rem;
    padding-left: 20px;
    padding-right: 20px;
}

.decisive_coaching_h3 li {
    list-style-type: square;
    margin-bottom: 10px;
}

.decisive_coaching_h3 {
    grid-area: decisive_coaching_h3;
    color: var(--color-secondary);
    font-size: 1.2rem;
    padding-right: 20px;
}

.about_h2 {
    grid-area: about_h2;
    color: var(--color-secondary);
    font-size: 1.2rem;
    padding: 20px;
}

.about_h2 li {
    list-style-type: circle;
    margin-bottom: 10px;
}

.coaching_is_h1 {
    grid-area: coaching_is_h1;
    background-color: var(--color-secondary);
    color: white;
    padding-left: 20px;
    padding-right: 20px;
}

/*.coaching_is_h3 {
    grid-area: coaching_is_h3;
    padding-left: 20px;
    padding-right: 20px;
}*/

.coaching_is_h3 {
    grid-area: coaching_is_h3;
    position: relative;
    font-size: 2.5rem;
    background-color: var(--color-primary);
    border: var(--color-primary);
    color: white;

    padding-right: 20px;
    padding-left: 20px;
    /* Adjust based on your design */
}

.coaching_is_h3 h3 {
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.coaching_is_h3 h3.visible {
    opacity: 1;
}

.coaching_is_h3 h3.hidden {
    opacity: 0;
}


.landmark_h2 {
    display: flex;
    grid-area: landmark_h2;
    color: var(--color-secondary);
    align-items: flex-end;
}

.landmark_h2_text {
    transform: translateY(25%);
    font-size: 2rem;
    margin-left: -4px;
}

.chronology_h2 {
    grid-area: chronology_h2;
}

.for_executive_h1,
.and_company_h1 {
    /*display: flex;*/
    /*align-items: flex-end;*/
    color: var(--color-secondary);
    /*overflow: visible;*/
}

.landmark {
    display: flex;
    justify-content: space-between;
    /* Centrage horizontal des colonnes */
    align-items: baseline;
    /* Alignement vertical des items */
    padding: 10px;
}

.landmark_year {
    display: flex;
    font-size: 0.8rem;
    color: var(--color-secondary);
    font-weight: bold;
    line-height: 3;
    margin-top: 5px;
    /* Espacement entre le nom et l'année */
}

.landmark_name {
    font-size: 1.35rem;
    color: var(--color-secondary);
    text-align: left;
}

@media (max-width: 1506px) {
    .landmark {
        line-height: 1.5;
    }

    .landmark_name {
        font-size: 1.2rem;
    }

    .landmark_year {
        font-size: 0.7rem;
    }
}

@media (max-width: 1360px) {
    .landmark {
        line-height: 1;
    }

    .landmark_name {
        font-size: 1rem;
    }

    .landmark_year {
        font-size: 0.55rem;
    }
}